.page-error {
	flex-grow: 1;
	align-items: center;
	display: flex;

	.page-error__title {
		@include font-size($font-size-xl * 2);
		color: $blue;
	}
	.page-error__desc {
		@include font-size($font-size-xl);
	}
}
