.ticker {
	min-height: 78px;
	line-height: 1.2;
	min-height: calc((2rem * 1.2) + 40px);
	padding: 20px 0;
	background-color: $black;
	color: #fff;
	@include font-size($font-size-main);
	font-weight: 600;
	letter-spacing: 0;
	width: 100%;

	.ticker__line {
		height: 0;
		overflow: hidden;

		span {
			color: $black;
			transition: color 1s linear;
		}

		&--active {
			height: auto;
			//min-height: 43px;
			-webkit-line-clamp: 2;
			text-overflow: ellipsis;
			-webkit-box-orient: vertical;
			display: -webkit-box;
			overflow: hidden;

			span {
				color: white;
			}
		}
	}
}

@media (max-width: map.get($grid-breakpoints,"sm") - 0.02) {
	.ticker {
		font-size: 14px;
		padding: 10px 0;
		min-height: 80px;

		.ticker__line {
			&--active {
				-webkit-line-clamp: 3;
			}
		}
	}
}
