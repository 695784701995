.features {
	margin-bottom: 120px;

	.features__item {
		margin-top: 120px;
	}
	.features__item-icon {
		//text-align: right;
		//padding-right: 100px;
		text-align: center;

		img {
			max-width: 140px;
			max-height: 140px;
			width: auto;
			height: auto;
			margin-top: -20px;
		}
	}
	.feature__title {
		@include font-size($font-size-xxl);
		font-weight: $fw-black;
		line-height: 1.2;
		margin-bottom: 5px;
	}
	.feature__sub-title {
		@include font-size($font-size-large);
		font-weight: $fw-bold;
		line-height: 1.2;
		margin-bottom: 35px;
	}
	.feature__desc {
		//@include font-size($font-size-main);
		line-height: 1.3;

		strong {
			//font-weight: $fw-black;
		}
		p {
			margin-bottom: 20px;

			&:nth-last-child(1) {
				margin-bottom: 0;
			}
		}
	}
	/*
	.features__item--right {
		
		.features__item-icon {
			order: 10;
			text-align: left;
			padding-left: 100px;
		}
		.features__item-content {
			order: 5;
			margin-left: calc((100% / 12) * 3);
		}
	}
	*/
}

@media (max-width: map.get($grid-breakpoints,"xxl") - 0.02px) {
	.features {
		.feature__title {
			@include font-size($font-size-xl);
		}
		.feature__sub-title {
			@include font-size($font-size-large);
		}
		.feature__desc {
			//@include font-size($font-size-main);
		}

		.features__item-icon {
			img {
				max-width: 120px;
				max-height: 120px;
			}
		}
	}
}
@media (min-width: map.get($grid-breakpoints,"xxl")) {
	.features {
		background: {
			position: right top, left top 50%, right bottom;
			image: url(../images/bg-eye-left.svg), url(../images/bg-eye-right.svg), url(../images/bg-eye-left.svg);
			repeat: no-repeat;
			size: auto 28%;
		}
	}
}

@media (max-width: map.get($grid-breakpoints,"lg") - 0.02) {
	.features {
		background-image: none;

		.features__item {
			margin-top: 90px;
		}
		.features__item-icon {
			padding-right: 20px;
			padding-left: 0;

			img {
				max-width: 100px;
				max-height: 100px;
			}
		}
		/*
		.features__item--right {
			.features__item-content {
				margin-left: 0;
			}
			.features__item-icon {
				padding-left: 0;
			}
		}*/
	}
}
@media (max-width: map.get($grid-breakpoints,"md") - 0.02) {
	.features {
		margin-bottom: 60px;
		.features__item {
			margin-top: 60px;
		}
		.features__item-icon {
			text-align: center;
			margin-bottom: 25px;

			img {
				max-width: 160px;
				max-height: 160px;
			}
		}
	}
}
@media (max-width: map.get($grid-breakpoints,"sm") - 0.02) {
	.features {
		margin-bottom: 30px;

		.features__item {
			margin-top: 50px;

			.features__item-icon {
				//order: 0;
			}
		}
		.features__item-icon {
			margin-bottom: 15px;

			img {
				max-height: 100px;
				max-width: 100px;
			}
		}
	}
}
