.header {
	height: 90px;
	background-color: $blue;
	display: flex;
	//box-shadow: 3px 5px 15px 10px rgb(0 0 0 / 4%);
	align-items: center;
	position: fixed;
	top: 0;
	letter-spacing: 0;
	z-index: 50;

	transition: height 0.2s linear;

	.header__logo {
		margin-top: -5px;

		&-main {
			width: 200px;
			height: auto;
		}
		&-small {
			display: none;
			height: 32px;
			width: auto;
		}
	}
	.header__nav {
		margin: 0;
		padding: 0;
		list-style-type: none;
		@include font-size(18px);
		text-transform: uppercase;
		color: $white-font;
		font-weight: $fw-bold;
		display: flex;

		a {
			color: inherit;
			text-decoration: none;

			&:hover {
				color: $yellow;
			}
		}
		li {
			margin-left: 75px;

			&:nth-child(1) {
				margin-left: auto;
			}
		}
	}

	&--small {
		height: 70px;

		.header__logo {
			margin-top: 0;
		}
		.header__logo-main {
			display: none;
		}
		.header__logo-small {
			display: block;
		}
	}
}

body {
	padding-top: 90px; // header
}
@media (max-width: map.get($grid-breakpoints,"xl")) {
	.header .header__nav li {
		margin-left: 35px;
	}
}
@media (max-width: map.get($grid-breakpoints,"lg")) {
	body {
		padding-top: 60px; // header
	}
	.header {
		height: 60px;
		border-bottom: 1px solid #3e97c7;

		.header__logo-main {
			width: auto;
			height: 45px;
		}

		&--small {
			height: 50px;
			.header__logo-small {
				width: auto;
				height: 25px;
			}
		}
	}
}
@media (min-width: map.get($grid-breakpoints,"lg")) {
	.header {
		.container {
			padding-left: 80px;
			padding-right: 80px;
			max-width: 100%;
		}
	}
}

// mobile nav
@media (max-width: map.get($grid-breakpoints,"md") - 0.02) {
	body {
		padding-top: 70px; // header
	}
	.header {
		height: 70px;

		.header__logo {
			text-align: center;
			margin-top: 0;
		}
		.header__logo-main,
		.header__logo-small {
			display: none;
		}
		.header__logo-mobile {
			display: inline;
			//height: 80px;

			width: 180px;
			height: auto;
		}
		.header__nav-wrap {
			display: none;

			&--active {
				margin: 0;
				padding: 0;
				display: block;
				position: absolute;
				top: 100%;
				width: 300px;
				background: #0579b8;
				box-shadow: inset 0px 5px 5px rgb(0 0 0 / 15%), 0px 0px 15px 0px rgb(0 0 0 / 10%);

				.header__nav {
					margin: 0;
					text-align: center;
					flex-direction: column;
				}
				.menu-item {
					margin: 0;
					width: 100%;
					border-top: 1px solid rgba(#fff, 0.5);
					display: block;
					a {
						display: block;
						padding: 15px 10px;
					}
					&:nth-child(1) {
						border-top-width: 0;
					}
				}
			}
		}
		.header__mobile-nav {
			position: absolute;
			width: 50px;
			height: 50px;
			top: 10px;
			right: 10px;
			padding: 0;
			background: {
				image: url(../images/icon-menu-white.svg);
				size: 30px auto;
				position: center;
				repeat: no-repeat;
				//color: rgba(0, 0, 0, 0.09);
			}

			&--active {
				background-image: url(../images/icon-close-white.svg);
			}
		}
	}
}
@media (max-width: map.get($grid-breakpoints,"sm") - 0.02) {
	.header {
		.header__mobile-nav {
			background-size: 25px auto;
		}
	}
}
