.features-list {
	margin-top: 80px;
	margin-bottom: 80px;

	@include font-size($font-size-main);

	.features-list__title {
		margin-bottom: 60px;
	}

	.features-list__item-wrap {
		margin-bottom: 35px;
	}
	.features-list__item {
		display: flex;
	}
	.features-list__content {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
	.features-list__icon {
		margin-right: 50px;
		display: flex;
		align-items: center;

		img {
			width: 60px;
			height: auto;
		}
	}
	.features-list__item-title {
		margin-bottom: 5px;
	}
}

@media (max-width: (map.get($grid-breakpoints,"lg") - 0.02px)) {
	.features-list {
		margin-top: 60px;
		margin-bottom: 60px;

		.features-list__icon img {
			width: 60px;
		}
		.features-list__title {
			//padding-bottom: 5px;
			margin-bottom: 60px;
		}
	}
}

@media (max-width: map.get($grid-breakpoints,"sm") - 0.02) {
	.features-list {
		.features-list__title {
			margin-bottom: 30px;
		}
		.features-list__item {
			flex-direction: column;
			align-items: center;
		}
		.features-list__icon {
			margin-left: 0%;
			margin-right: 0;
		}
		.features-list__icon {
			margin-bottom: 30px;

			img {
				width: 70px;
				height: auto;
			}
		}
	}
}
