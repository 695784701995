.contact {
	background: $black;
	padding-top: 120px;
	padding-bottom: 120px;
	@include font-size($font-size-main);
	color: #fff;

	.contact__title {
		@include font-size(34px);
		font-weight: 600;
		margin-bottom: 40px;
	}
	.contact__sub-title {
		margin-bottom: 20px;
	}
	.contact__actions {
		margin-bottom: 20px;

		.btn {
			color: $black;
			margin: 0 15px;
			padding-left: 60px;
			padding-right: 60px;
		}
	}
	.contact__phone {
		margin-top: 20px;
		a {
			color: $yellow;
			text-decoration: none;

			&:hover {
				text-decoration: underline;
			}
		}
	}
}

@media (max-width: (map.get($grid-breakpoints,"lg") - 0.02px)) {
	.contact {
		padding-top: 70px;
		padding-bottom: 70px;

		.contact__actions .btn {
			padding-left: 40px;
			padding-right: 40px;
		}
	}
}

@media (max-width: (map.get($grid-breakpoints,"md") - 0.02px)) {
	.contact {
		padding-top: 50px;
		padding-bottom: 50px;

		.contact__actions .btn {
			padding-left: 30px;
			padding-right: 30px;
			margin: 0 5px;
		}
	}
}
@media (max-width: (map.get($grid-breakpoints,"sm") - 0.02px)) {
	.contact {
		.contact__actions {
			.btn {
				margin: 10px 0;
				padding: 0 60px;
			}
		}
		.contact__phone {
			a {
				display: block;
			}
		}
	}
}
