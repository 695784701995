input[type="text"],
input[type="email"],
input[type="password"],
textarea {
	border: 1px solid rgba(#fff, 0.5);
	border-radius: 12px;
	height: 50px;
	width: 100%;
	appearance: none;
	background-color: transparent;
	@include font-size($font-size-main);
	padding-left: 20px;
	padding-right: 30px;
	color: #fff;

	&:focus {
		border-color: #fff;
	}
}
textarea {
	height: 120px;
	resize: vertical;
	padding-top: 20px;
	padding-bottom: 20px;
}

input[type="checkbox"] {
	width: 18px;
	height: 18px;
	background-color: #fff;
	border-radius: 5px;
}

$placeholder-color: rgba(#fff, 0.4);
::-webkit-input-placeholder {
	/* Chrome/Opera/Safari */
	color: $placeholder-color;
}
::-moz-placeholder {
	/* Firefox 19+ */
	color: $placeholder-color;
}
:-ms-input-placeholder {
	/* IE 10+ */
	color: $placeholder-color;
}
:-moz-placeholder {
	/* Firefox 18- */
	color: $placeholder-color;
}

.btn {
	height: 50px;

	&-secondary {
		//color: #fff;
	}
}
@media (max-width: (map.get($grid-breakpoints,"lg") - 0.02px)) {
	.btn {
		height: 45px;
		line-height: 45px;
		padding-left: 30px;
		padding-right: 30px;
	}
}
@media (max-width: map.get($grid-breakpoints,"sm") - 0.02) {
	.btn {
		font-size: 16px;
		padding-left: 15px;
		padding-right: 15px;
	}
}
