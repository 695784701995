body.bottom-bar {
	padding-bottom: 49px;
}

.cookie-bar {
	position: fixed;
	z-index: 200;
	bottom: 0;
	left: 0;
	width: 100%;
	padding: 0 15px;
	height: 49px;
	line-height: 49px;
	background: $blue;
	text-align: center;
	color: #fff;
	font-family: "Trueno", Helvetica, sans-serif;
	font-weight: normal;
	font-size: 16px;
	opacity: 0.85;
	transition: opacity 0.2s linear;
	//display: none;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;

	&:hover {
		opacity: 1;
	}

	a {
		color: inherit;
		text-decoration: underline;
		font-weight: $font-weight-bold;
	}
	.cookie-bar__accept {
		height: 30px;
		line-height: 30px;
		font-size: 16px;
		vertical-align: unset;
		margin-left: 15px;
	}
}
@media (max-width: map.get($grid-breakpoints,"xl") - 0.02px) {
	body.bottom-bar {
		padding-bottom: 70px;
	}

	.cookie-bar {
		height: 70px;
		line-height: 1.5;
		padding-top: 5px;

		.cookie-bar__accept {
			margin: 5px auto 0;
			display: block;
			width: 240px;
			padding: 0;
		}
	}
}
@media (max-width: map.get($grid-breakpoints,"lg") - 0.02px) {
	.cookie-bar {
		.cookie-bar__accept {
			display: inline-block;
			height: 30px;
			font-size: inherit;
			margin-left: 15px;
		}
	}
}
@media (max-width: map.get($grid-breakpoints,"md") - 0.02px) {
	body.bottom-bar {
		padding-bottom: 95px;
	}
	.cookie-bar {
		height: 95px;
	}
}
@media (max-width: map.get($grid-breakpoints,"sm") - 0.02px) {
	body.bottom-bar {
		padding-bottom: 140px;
	}

	.cookie-bar {
		height: 140px;
		max-width: 100vw;
		font-size: 14px;

		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		.cookie-bar__accept {
			margin: 10px auto 0;
		}
	}
}
