.demo {
	color: white;
	background-color: $black;
	padding-top: 40px;
	padding-bottom: 30px;
	//@include font-size($font-size-large);

	.demo__form-title,
	.demo__desc-title {
		text-align: center;
		@include font-size($font-size-xxl);
		line-height: 1.2;
		margin-bottom: 70px;
		font-weight: $fw-bold;
	}
	.demo__form-title {
		color: $yellow;
	}
	.demo__desc-content {
		p {
			margin-bottom: 10px;
		}
		ul {
			margin: 0;
			padding: 0;
			list-style-type: none;

			li {
				margin-bottom: 30px;
				position: relative;
				padding-left: 25px;

				&::before {
					content: "\2022";
					color: $yellow;
					display: inline-block;
					font-size: 50px;
					line-height: 0px;
					position: absolute;
					top: 10px;
					left: 0;
				}

				&:nth-last-child(1) {
					margin-bottom: 0;
				}
			}
		}
	}
	.wpcf7-form {
		max-width: 500px;
		margin: 0 auto;
	}
}
@media (max-width: map.get($grid-breakpoints,"xl") - 0.02) {
	.demo {
		@include font-size($font-size-main);

		.demo__form-title,
		.demo__desc-title {
			@include font-size($font-size-xl);
			margin-bottom: 60px;
		}
		.demo__desc-content {
			p {
				margin-bottom: 15px;
			}
		}
	}
}
@media (max-width: map.get($grid-breakpoints,"lg") - 0.02) {
	.demo {
		padding-top: 0;
		padding-bottom: 60px;

		.demo__form-title,
		.demo__desc-title {
			margin-top: 60px;
			margin-bottom: 60px;
		}
	}
}
@media (max-width: map.get($grid-breakpoints,"md") - 0.02) {
	.demo {
		.demo__form-title,
		.demo__desc-title {
			margin-top: 40px;
			margin-bottom: 40px;
		}
		.demo__desc-content ul li {
			padding-left: 20px;

			&::before {
				font-size: 35px;
			}
		}
		.wpcf7-form {
			max-width: 420px;
		}
	}
}
@media (max-width: map.get($grid-breakpoints,"md") - 0.02) {
	.demo {
	}
}
