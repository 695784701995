.footer {
	margin-top: auto;

	.footer__copy {
		align-self: center;
	}
	.footer__icons {
		background-color: $blue;
		padding-top: 2px;
		padding-bottom: 2px;
		height: 80px;
		color: #fff;
		display: flex;
		align-items: center;

		.col {
			display: flex;
			align-items: center;
			flex-grow: 0;
		}
		.row {
			justify-content: space-between;
		}
	}

	.footer__icon {
		width: 45px;
		height: 45px;
		line-height: 0;
		margin-right: 15px;

		&-desc {
			align-self: center;
		}
	}
	.footer__lng-select {
		color: #fff;
		align-self: center;
		//margin-left: auto;
		@include font-size($font-size-main);
		min-width: 120px;
		appearance: none;
		border: 0;
		background-color: transparent;
		color: #000;
		text-align-last: right;

		padding: 10px 25px 10px 0;

		background: {
			image: url(../images/arrow-bottom.svg);
			size: 30px auto;
			repeat: no-repeat;
			position: right -8px center;
		}
	}
	.footer__nav {
		color: $blue;
		background: $black;
		//@include font-size($font-size-small);
		line-height: 1.2;
		padding-top: 15px;
		padding-bottom: 15px;
	}

	.footer__nav-menu {
		list-style-type: none;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		height: 100%;
		padding: 0;
		margin: 0;
		.menu-item {
			margin-left: 50px;

			&:nth-child(1) {
				margin-left: 0;
			}
		}
	}

	.footer__lng-select-wrap {
		display: flex;
		justify-content: flex-end;
	}
	.nice-select {
		color: #fff;
		height: auto;
		line-height: 1.5;

		&::after {
			content: none !important;
		}
	}
	.nice-select-dropdown {
		text-align: right;
		top: auto !important;
		bottom: 100% !important;
		transition: none !important;
		color: #000;
	}
}
@media (min-width: (map.get($grid-breakpoints,"xxl"))) {
	.footer {
		.footer__icons {
			.col {
				width: auto;
			}
		}
	}
}
@media (max-width: (map.get($grid-breakpoints,"lg") - 0.02px )) {
	.footer {
		.container {
			max-width: none;
		}
		.footer__nav-menu {
			justify-content: center;

			.menu-item {
				margin-left: 15px;

				&:nth-child(1) {
					margin-left: 0;
				}
			}
		}
		.footer__nav {
			font-size: 14px;
		}
	}
}
@media (min-width: map.get($grid-breakpoints,"sm")) and (max-width: (map.get($grid-breakpoints,"md") - 0.02px)) {
	.footer {
		.footer__icons {
			font-size: 14px;
			padding-bottom: 15px;
			height: auto;

			.col {
				margin-top: 15px;
				/*
				&:nth-child(2n) {
					flex-direction: row-reverse;

					.footer__icon {
						margin-right: 0;
						text-align: revert;
						margin-left: 25px;
					}
					.footer__icon-desc {
						text-align: right;
					}
				}*/
			}
		}
	}
}
@media (max-width: (map.get($grid-breakpoints,"md") - 0.02px )) {
	.footer {
		.footer__nav {
			position: relative;
			text-align: center;
		}
		.footer__nav-menu-wrap {
			justify-content: center;
			margin-top: 10px;
		}
		.footer__nav-menu {
			justify-content: center;
		}
		.footer__lng-select-wrap {
			position: absolute;
			width: auto;
			top: 38%;
			right: 0px;
		}
	}
}
@media (max-width: map.get($grid-breakpoints,"sm") - 0.02px) {
	.footer {
		.footer__copy {
			text-align: center;
		}
		.footer__icons {
			height: auto;

			.col {
				margin: 15px 0;
			}
		}
		.footer__lng-select-wrap {
			margin-top: 15px;
			justify-content: center;
			position: static;
			width: 100%;
		}
	}
}
