/*
html {
	scroll-snap-type: y proximity;
	scroll-padding-top: 115px;
}
body.home {
	main > section {
		scroll-snap-align: start;
	}
}
*/
