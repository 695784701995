.main-desc {
	padding-top: 60px;
	@include font-size($font-size-main);

	.main-desc__content {
		padding-top: 60px;
	}
}

@media (max-width: (map.get($grid-breakpoints,"lg") - 0.02px)) {
	.main-desc {
		padding-top: 40px;

		.main-desc__content {
			padding-top: 40px;
		}
	}
}
