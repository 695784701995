body .wpcf7-form {
	.wpcf7-not-valid-tip {
		@include font-size($font-size-main);
	}
	.wpcf7-response-output {
		@include font-size($font-size-small);
		padding: 10px 15px;
	}
	br {
		display: none;
	}
	.ajax-loader {
		display: none;
	}
	.wpcf7-form-control-wrap {
		margin-bottom: 15px;
		display: block;

		&:nth-last-child(1) {
			margin-bottom: 0;
		}
	}
	& > p {
		margin-bottom: 0;
	}

	.wpcf7-submit {
		text-transform: uppercase;
		padding-left: 40px;
		padding-right: 40px;
		color: $black;
		margin: 30px auto 0 auto;
		display: block;
	}
	.wpcf7-acceptance {
		a {
			text-decoration: underline;
		}
		.wpcf7-list-item {
			margin-left: 0;
			position: relative;
		}
		input[type="checkbox"] {
			position: absolute;
			left: 0;
			top: 5px;
		}
		.wpcf7-list-item-label {
			margin-left: 30px;
			display: block;
			@include font-size($font-size-small);
		}
	}
}

@media (max-width: map.get($grid-breakpoints,"lg") - 0.02px) {
	.wpcf7-form {
		.wpcf7-submit {
			margin-left: auto;
			margin-right: auto;
		}
	}
}
