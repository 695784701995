@use "sass:map";

.main-slider {
	background-color: $blue;
	//padding: 0 0 115px;
	position: relative;

	background: {
		image: url(../images/bg-rocket.svg);
		position: top -10vw right 140px;
		repeat: no-repeat;
		size: 50% auto;
	}

	&::before {
		content: "";
		position: absolute;
		bottom: 100%;
		left: 0;
		height: 90px;
		width: 100%;
		background-color: $blue;
	}

	.tns-inner,
	.tns-outer,
	.home-slider__inner {
		height: 100%;
	}

	.home-slider__inner {
		color: #fff;
		@include font-size($font-size-large + 2px);
		line-height: 1.3;
		z-index: 5;
	}
	.main-slider__slide {
		//padding-right: 62%;
		height: 600px;

		p {
			margin-bottom: 20px;
		}
	}
	.main-slider__slide-inner {
		display: flex;
		flex-direction: column;
		justify-content: center;
		height: 100%;
		width: 40%;
		//padding: 0 80px;
	}
	.btn {
		margin-top: 30px;
		align-self: center;
	}
}
@media (min-width: (map.get($grid-breakpoints,"lg") )) and (max-width: map.get($grid-breakpoints,"xl")) {
	.main-slider {
		.main-slider__slide-inner {
			width: 55%;
		}
	}
}
@media (min-width: (map.get($grid-breakpoints,"lg") )) and (max-width: map.get($grid-breakpoints,"xxl")) {
	.main-slider {
		background-position: bottom -15vw right 10px;
		background-size: 65% auto;

		.btn {
			height: 60px;
			line-height: 60px;
			@include font-size($font-size-large);
			//float: right;
		}
	}
}

@media (min-width: (map.get($grid-breakpoints,"sm"))) and (max-width: (map.get($grid-breakpoints,"lg") - 0.02px)) {
	.main-slider {
		background-image: none;
		position: relative;

		.main-slider__slide-inner {
			justify-content: flex-start;
			font-size: 16px;
			width: 50%;
			padding: 40px 0;
		}

		.main-slider__slide {
			height: 400px;
		}
		.btn {
			font-size: inherit;
			align-self: flex-start;
		}
	}
}
@media (min-width: map.get($grid-breakpoints,"sm")) and (max-width: map.get($grid-breakpoints,"md") - 0.02) {
	.main-slider {
		.container {
			max-width: none;
		}
		.main-slider__slide-inner {
			width: 60%;
			//justify-content: flex-start;
		}
		&::after {
			background: {
				position: top -17vw right -60px;
				size: 100% auto;
			}
		}
	}
}

@media (max-width: map.get($grid-breakpoints,"lg") - 0.02) {
	.main-slider {
		background-image: none;
		position: relative;

		&::after {
			z-index: 1;
			position: absolute;
			width: 100%;
			height: 100%;
			opacity: 0.6;
			top: 0;
			left: 0;
			content: "";
			background: {
				image: url(../images/bg-rocket.svg);
				position: top -20vw right -70px;
				repeat: no-repeat;
				size: 90% auto;
			}
		}
	}
}
@media (max-width: map.get($grid-breakpoints,"sm") - 0.02) {
	.main-slider {
		&::after {
			//background-image: none;
			opacity: 0.4;
			background-position: center right -10vw;
			background-size: 660px;
		}
		.main-slider__slide {
			height: 500px;
		}
		.main-slider__slide-inner {
			font-size: 16px;
			width: 100%;
			justify-content: flex-end;
			padding-bottom: 30px;
		}
		.main-slider__slide {
		}
		.btn {
			margin-top: 10px;
			font-size: 14px;
			font-size: inherit;
		}
	}
}
