/*
@font-face {
	font-family: "Trueno";
	src: local("Trueno Light"), local("TruenoLt"), url("../fonts/TruenoLt.woff2") format("woff2"),
		url("../fonts/TruenoLt.woff") format("woff"), url("../fonts/TruenoLt.ttf") format("truetype");
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: "Trueno";
	src: local("Trueno SemiBold"), local("TruenoSBd"), url("../fonts/TruenoSBd.woff2") format("woff2"),
		url("../fonts/TruenoSBd.woff") format("woff"), url("../fonts/TruenoSBd.ttf") format("truetype");
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}
*/

@font-face {
	font-family: "Trueno";
	src: local("Trueno Bold"), local("TruenoBd"), url("../fonts/TruenoBd.woff2") format("woff2"),
		url("../fonts/TruenoBd.woff") format("woff"), url("../fonts/TruenoBd.ttf") format("truetype");
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Trueno";
	src: local("Trueno Regular"), local("TruenoRg"), url("../fonts/TruenoRg.woff2") format("woff2"),
		url("../fonts/TruenoRg.woff") format("woff"), url("../fonts/TruenoRg.ttf") format("truetype");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Trueno";
	src: local("Trueno Black"), local("TruenoBlk"), url("../fonts/TruenoBlk.woff2") format("woff2"),
		url("../fonts/TruenoBlk.woff") format("woff"), url("../fonts/TruenoBlk.ttf") format("truetype");
	font-weight: 900;
	font-style: normal;
	font-display: swap;
}
