.contact-page {
	display: flex;
	flex-grow: 1;
	padding-left: 0;
	padding-right: 0;

	.row {
		height: 100%;
	}

	.contact-page__info-wrap {
		display: flex;
	}
	.contact-page__content,
	.contact-page__addr {
		margin: 0 auto;
	}
	.contact-page__content {
		@include font-size($font-size-main);
		max-width: 630px;
		padding: 0 15px;
		min-height: 560px;

		h2,
		h3,
		h4 {
			margin-bottom: 25px;
		}
	}
	.contact-page__addr {
		line-height: 1.4;
		text-align: center;
		@include font-size($font-size-large);
		display: flex;
		flex-direction: column;
		justify-content: center;

		p,
		strong {
			margin-bottom: 0;
		}
	}
	.contact-page__content-title {
		text-transform: uppercase;
		margin-bottom: 25px;
	}

	a {
		color: inherit;
	}
	a,
	strong {
		margin-bottom: 0;
		display: inline-block;
	}
	.contact-page__form-title {
		text-align: center;
		color: $yellow;
		text-transform: uppercase;
		margin-bottom: 40px;
	}
	.contact-page__form-wrap {
		background-color: $black;
		padding: 40px 0;
		color: #fff;
		display: flex;
		justify-content: center;
		flex-direction: column;

		p {
			margin-bottom: 0;
		}
	}
	.contact-page__form {
		width: 100%;
	}

	.wpcf7-form {
		max-width: 500px;
		margin: 0 auto;

		.wpcf7-submit {
			margin-left: auto;
			margin-right: auto;
		}
	}
}
@media (min-width: map.get($grid-breakpoints,"lg")) {
	.contact-page {
		.container {
			max-width: 100%;
		}
	}
}

@media (min-width: map.get($grid-breakpoints,"lg")) and (max-width: map.get($grid-breakpoints,"xl") - 0.02px) {
	.contact-page {
		.wpcf7-form {
			max-width: 400px;
		}
	}
}
@media (max-width: map.get($grid-breakpoints,"lg") - 0.02px) {
	.contact-page {
		.container {
			max-width: 100%;
		}

		.contact-page__content {
			min-height: auto;
			padding-top: 15px;
			padding-bottom: 15px;
		}

		$padding: 80px;
		.contact-page__info-wrap {
			padding-top: $padding;
			padding-bottom: $padding;
		}
		.contact-page__form-wrap {
			padding-top: $padding;
			padding-bottom: $padding;
		}

		.contact-page__addr {
			@include font-size($font-size-main);
		}

		.wpcf7-form {
			max-width: 450px;
		}
	}
}
@media (max-width: map.get($grid-breakpoints,"md") - 0.02px) {
	.contact-page {
		$padding: 30px;
		.contact-page__info-wrap {
			padding-top: $padding;
			padding-bottom: $padding;
		}
		.contact-page__form-wrap {
			padding-top: $padding;
			padding-bottom: $padding;
		}
	}
}

@media (max-width: map.get($grid-breakpoints,"sm") - 0.02px) {
	.contact-page {
		.contact-page__addr {
			line-height: 1.7;
		}
		.wpcf7-form {
			max-width: 300px;
		}
	}
}
