@use "sass:map";

@import "colors.scss";

// fonts
$fw-black: 900;
$fw-bold: bold;

// fonts
$font-size-small: 14px;
$font-size-main: 18px;
$font-size-large: 24px;
$font-size-xl: 30px;
$font-size-xxl: 38px;

$font-size-root: 16px;
$font-family-base: "Trueno";
$line-height-base: 1.2;
$body-color: #000;

// buttons
$btn-border-radius: 25px;
$btn-padding-x: 40px;
$btn-padding-y: 0;
$btn-line-height: 50px;
$btn-font-size: $font-size-main;
$btn-font-weight: $fw-black;
$btn-border-width: 0;

// headings
$font__size-xl: 44px; // h1
$font__size-lg: 40px; // h2/h3
$font__size-md: 30px; // h4
$font__size-sm: 26px; // h5
$font__size-xs: 24px; // h6
$headings-font-weight: $fw-black;

* {
	outline: none !important;
}

@import "fonts.scss";
@import "bootstrap.scss";
body {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	font-size: $font-size-main;
}
.container-fluid {
	& > .row {
		flex-grow: 1;
	}
}
p {
	margin-bottom: 30px;

	&:nth-last-child(1) {
		margin-bottom: 0;
	}
}
a {
	text-decoration: none;
}
.hidden {
	display: none;
}
strong {
	font-weight: $fw-bold;
}
h1 {
	strong {
		color: $blue;
		font-weight: inherit;
	}
}
h1,
h2,
h3 {
	margin-bottom: 30px;
}

h4,
h5,
h6 {
	font-weight: $fw-bold;
	margin-bottom: 30px;
}

@import "inputs";
//
@import "components/header";
@import "components/main-slider";
@import "components/ticker";
@import "components/main-desc";
@import "components/features-home";
@import "components/contact";
@import "components/footer";
@import "components/features";
@import "components/contact-demo";
@import "components/contact-page";
@import "components/wpcf-global";
@import "components/cookie-bar";
//
@import "templates/home";
@import "templates/404";
@import "templates/single";

@media (min-width: 1480px) {
	/*
	.container {
		max-width: 1440px;
	}
	*/
}
@media (max-width: map.get($grid-breakpoints,"md") - 0.02px) {
	:root {
		font-size: 14px;
	}
}

@media (max-width: map.get($grid-breakpoints,"sm") - 0.02px) {
	:root {
		font-size: 13px;
	}
}
