.content {
	word-break: break-word;

	table {
		table-layout: fixed;
		margin-bottom: 10px;

		tr:nth-child(odd) {
			background-color: #f0f0f0;
		}
		td {
			padding: 7px 15px;
		}
	}
	p {
		margin-bottom: 15px;
	}
	h1,
	h2,
	h3,
	h4 {
		padding-top: 25px;

		&:nth-child(1) {
			padding-top: 0;
		}
	}
}
.single {
	.single__title {
		text-transform: uppercase;
	}
	.single__content {
	}
}
